import http from '@/utils/http';

export const getPlayerStatList = (parameter) => {
    return http.request({
        url: '/v1/games/player_stat/list',
        method: 'get',
        params: parameter
    })
}

export const editPlayerStat = (parameter) => {
    return http.request({
        url: '/v1/games/player_stat/edit',
        method: 'post',
        data: parameter
    })
}
