import http from '@/utils/http';

export const TeamInfo = (parameter) => {
  return http.request({
    url: '/v1/games/team/info',
    method: 'get',
    params: parameter
  })
}

export const TeamSearch = (parameter) => {
  return http.request({
    url: '/v1/games/team/search',
    method: 'get',
    params: parameter
  })
}

export const getTeams = (parameter) => {
  return http.request({
    url: '/v1/games/team/list',
    method: 'post',
    params: parameter
  })
}

export const editTeam = (parameter) => {
  return http.request({
    url: '/v1/games/team/edit',
    method: 'post',
    data: parameter
  })
}