<template>
    <Row class="flex flex-nowrap">
        <!-- v-if="hour>0" -->
        <Input-Number v-model="hour" number placeholder="小时" @on-change="updateValue" ></Input-Number>
        <span class='text-main'>时</span>
        <Input-Number v-model="minute" number placeholder="分钟" @on-change="updateValue"></Input-Number>
        <span class='text-main'>分</span>
        <Input-Number v-model="second" number placeholder="秒" @on-change="updateValue"></Input-Number>
    </Row>
</template>
<script>
    export default {
        name: 'Duration',
        props: ['value'],
        data () {
            return {
                hour:0,
                minute:0,
                second:0,
                duration:0
            }
        },
        watch:{
            duration(value){
                this.second=value%60;
                this.minute=Math.floor((value-value%60)/60)%60;
                this.hour=Math.floor(value/3600);
            },
            value(val){
                this.duration=val;
            }
        },
        mounted(){
            this.duration=this.value
        },
        methods: {
            updateValue(){
                this.$emit('input', this.second+this.minute*60+this.hour*3600);
            }
        }
    }
</script>
