<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
                <Form label-position="left" :label-width="80" >
                    <FormItem label="id" v-if="data.id">
                        <Input v-model="data.id"  disabled></Input>
                    </FormItem>
                    <FormItem label="比赛id" v-if="$unit.filterGameids( [1,2,3], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.match_id" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="小局比赛id">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.match_single_id" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    
                    <FormItem label="战队">
                        <Row>
                            <Col span="20" >
                                <teamSearch v-model="data.team" :game_id="game_id" ></teamSearch>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="选手">
                        <Row>
                            <Col span="20" >
                                <playerSearch v-model="data.player" :game_id="game_id" ></playerSearch>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="英雄" v-if="$unit.filterGameids( [1,3,6], game_id )">
                        <Row>
                            <Col span="20" >
                                <heroSearch v-model="data.hero" :game_id="game_id" ></heroSearch>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="等级"  v-if="$unit.filterGameids( [1,3,6], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.level" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="号位" v-if="$unit.filterGameids( [1,3,6], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.position">
                                    <Option v-for="(item, index) in $conf.games.positions[game_id]" :value="item.id" :key="item.id">{{item.name}}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="击杀"  >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.kill" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="爆头击杀" v-if="$unit.filterGameids( [2], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.hs" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="死亡"  >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.die" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="助攻"  >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.assists" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="闪光弹助攻" v-if="$unit.filterGameids( [2], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.f_assists" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="kast" v-if="$unit.filterGameids( [2], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.kast"  ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="adr" v-if="$unit.filterGameids( [2], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.adr"  ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="kf_diff" v-if="$unit.filterGameids( [2], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.kf_diff"  ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="awp击杀" v-if="$unit.filterGameids( [2], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.awp_kill"  ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="首杀轮次数" v-if="$unit.filterGameids( [2], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.frist_kill"  ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="impact" v-if="$unit.filterGameids( [2], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.impact"  ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="评分" v-if="$unit.filterGameids( [2], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.rating"  ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="补兵"  v-if="$unit.filterGameids( [1,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.soldiers" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="正补"  v-if="$unit.filterGameids( [3,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.last_hits" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    
                    <FormItem label="反补"  v-if="$unit.filterGameids( [3,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.denies" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="分均经济"  v-if="$unit.filterGameids( [3,], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.gold_per_min" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="分均经验" v-if="$unit.filterGameids( [3,], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.xp_per_min" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    
                    <FormItem label="对英雄伤害" v-if="$unit.filterGameids( [3,], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.hero_damage" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="对塔伤害" v-if="$unit.filterGameids( [3,], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.tower_damage" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="承伤" v-if="$unit.filterGameids( [1,3,6], game_id )"  >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.bear" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="参团率" v-if="$unit.filterGameids( [1,], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.part" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="经济" v-if="$unit.filterGameids( [1,6], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.money" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="输出" v-if="$unit.filterGameids( [1,6], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.attack" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="治愈" v-if="$unit.filterGameids( [3,], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.hero_healing" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="剩余金币" v-if="$unit.filterGameids( [3,], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.gold" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="消耗金币" v-if="$unit.filterGameids( [3,], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.gold_spent" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="装备" v-if="$unit.filterGameids( [1,3,6], game_id )" >
                        <Row>
                            <Col span="20" >
                                <equipmentsDragable v-model="data.equipments" :game_id=game_id ></equipmentsDragable>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="召唤师技能" v-if="$unit.filterGameids( [1,6], game_id )" >
                        <Row>
                            <Col span="20" >
                                <spellsDragable v-model="data.spells" :game_id=game_id ></spellsDragable>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="英雄符文" v-if="$unit.filterGameids( [1,], game_id )" >
                        <Row>
                            <Col span="20" >
                                <runesDragable v-model="data.runes" :game_id=game_id ></runesDragable>
                            </Col>
                        </Row>
                    </FormItem>
                    
                    <FormItem label="删除">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.deleted" >
                                    <Option :value="1" key="1">是</Option>
                                    <Option :value="0" key="0">否</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                </Form>
            </TabPane>
        </Tabs>

        <Row :gutter="16" align="middle">
            <Col span="12" style="text-align: left" >
                <Button type="default"  @click="cancle" long size="default">取消</Button>
            </Col>
            <Col span="12" style="text-align: right">
                <Button type="primary"  @click="save" long size="default">保存</Button>
            </Col>
        </Row>
    </div>
</template>
<script>

import { getPlayerStatList, editPlayerStat } from '@/api/games/player_stat';
import teamSearch from '../team/search.vue';
import playerSearch from '../player/search.vue';
import heroSearch from '../hero/search.vue';
import tournamentSearch from '../tournament/search.vue';
import Duration from '../../basic/duration.vue';
import equipmentsDragable from '../base/equipments_dragable.vue';
import spellsDragable from '../base/spells_dragable.vue';
import runesDragable from '../base/runes_dragable.vue';

export default {
    name: "match-edit",
    props: {
        game_id: Number,   // 运动id
        player_stat_id: Number,   // 比赛id
    },
    components: {
        teamSearch,
        tournamentSearch,
        Duration,
        playerSearch,
        heroSearch,
        equipmentsDragable,
        spellsDragable,
        runesDragable,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 1,
            type: 5, 
            spinShow: false,
            data: {
                game_id: self.game_id,
                team: {},
                player: {},
                hero: {},
            },
            locks: {},
        }
    },
    methods: {
        _init_data(){
            this.data = {
                game_id: this.game_id,
                team: {},
                player: {},
                hero: {},
            }
        },
        // 获取比赛的基础数据
        getData () {
            let self = this;
            this.spinShow = true;
            // 搜索日期
            if (self.player_stat_id > 0){
                var params = {
                    game_id: self.game_id,
                    player_stat_id: self.player_stat_id,
                }
                getPlayerStatList( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            self.locks = response.data.data.locks;
                        }else{
                            this.data = {}
                        }
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self._init_data();
            }
            self.spinShow = false;
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        cancle(){
            let self = this;
            self.emit("cancle")
        },
        initIds(datas){
            var ids =[];
            if (datas && datas.length > 0){
                datas.forEach(element => {
                    ids.push(element.id)
                });
            }
            return ids;
        },
        save() {
            let self = this;
            if (self.game_id == 3){
                var params = {
                    id: self.data.id,
                    game_id: self.data.game_id || self.game_id,
                    match_id: self.data.match_id,
                    detail_match_id: self.data.match_single_id,
                    team_id: self.data.team.id,
                    player_id: self.data.player.id,
                    hero_id: self.data.hero.id,
                    kill: self.data.kill ,
                    die: self.data.die ,
                    assists: self.data.assists ,
                    last_hits: self.data.last_hits ,
                    denies: self.data.denies ,
                    gold_per_min: self.data.gold_per_min ,
                    xp_per_min: self.data.xp_per_min ,
                    level: self.data.level ,
                    hero_damage: self.data.hero_damage ,
                    tower_damage: self.data.tower_damage ,
                    bear: self.data.bear ,
                    hero_healing: self.data.hero_healing ,
                    gold: self.data.gold ,
                    gold_spent: self.data.gold_spent ,
                    position: self.data.position,
                    hero_items: self.initIds( self.data.equipments ) ,
                    deleted: self.data.deleted,
                };
            }else if (self.game_id == 1){
                var params = {
                    id: self.data.id,
                    game_id: self.data.game_id || self.game_id,
                    match_id: self.data.match_id,
                    detail_match_id: self.data.match_single_id,
                    team_id: self.data.team.id,
                    player_id: self.data.player.id,
                    hero_id: self.data.hero.id,
                    hero_level: self.data.level ,
                    position_id: self.data.position,
                    kill: self.data.kill ,
                    die: self.data.die ,
                    assists: self.data.assists ,
                    soldiers: self.data.soldiers ,
                    part: self.data.part ,
                    money: self.data.money ,
                    attack: self.data.attack ,
                    bear: self.data.bear ,
                    hero_equipment: self.initIds( self.data.equipments ) ,
                    hero_spells: self.initIds( self.data.spells ) ,
                    hero_rune: self.initIds( self.data.runes ) ,
                    deleted: self.data.deleted,
                };
            }else if (self.game_id == 6){
                let spell_ids = self.initIds( self.data.spells );
                if (spell_ids.length > 0){
                    var hero_spell = spell_ids[0];
                }else{
                    var hero_spell = 0;
                }
                var params = {
                    id: self.data.id,
                    game_id: self.data.game_id || self.game_id,
                    single_match_id: self.data.match_single_id,
                    team_id: self.data.team.id,
                    player_id: self.data.player.id,
                    hero_id: self.data.hero.id,
                    hero_level: self.data.level ,
                    position_id: self.data.position,
                    kill: self.data.kill ,
                    die: self.data.die ,
                    assists: self.data.assists ,
                    economy: self.data.money ,
                    damage_dealt: self.data.attack ,
                    damage_taken: self.data.bear ,
                    hero_item: self.initIds( self.data.equipments ) ,
                    hero_spell: hero_spell, // kog 数字
                    deleted: self.data.deleted,
                };
            }else if (self.game_id == 2){
                var params = {
                    id: self.data.id,
                    game_id: self.data.game_id || self.game_id,
                    match_id: self.data.match_id,
                    single_id: self.data.match_single_id,
                    team_id: self.data.team.id,
                    player_id: self.data.player.id,
                    kill: self.data.kill ,
                    hs: self.data.hs ,
                    death: self.data.die ,
                    assists: self.data.assists ,
                    f_assists: self.data.f_assists ,
                    kast: self.data.kast,
                    adr: self.data.adr ,
                    kf_diff: self.data.kf_diff,
                    awp_kill: self.data.awp_kill ,
                    frist_kill: self.data.frist_kill ,
                    impact: self.data.impact ,
                    rating: self.data.rating ,
                    deleted: self.data.deleted,
                };
            }else{
                return
            }
            // console.log(params);
            editPlayerStat(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
    },
    watch: {
        'player_stat_id': {
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    self.getData();
                };
            },
            deep: false,
	        immediate: false,  // 深度触发
        },
        // end 
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>