import http from '@/utils/http';


export const searchPlayer = (parameter) => {
  return http.request({
    url: '/v1/games/player/search',
    method: 'post',
    data: parameter
  })
}

export const getPlayers = (parameter) => {
  return http.request({
    url: '/v1/games/player/list',
    method: 'post',
    params: parameter
  })
}

export const editPlayer = (parameter) => {
  return http.request({
    url: '/v1/games/player/edit',
    method: 'post',
    data: parameter
  })
}



export const updatePlayerMode = (parameter) => {
  return http.request({
    url: '/v1/games/player/data_mode/update',
    method: 'post',
    data: parameter
  })
}